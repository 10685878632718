//
// user chat.scss
//

// user chat

.user-chat {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  transition: all 0.4s;
  @media (max-width: 991.98px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 99;

    &.user-chat-show {
      visibility: visible;
      transform: translateX(0);
    }
  }
}

.user-chat-nav {
  .nav-btn {
    height: 40px;
    width: 40px;
    line-height: 40px;
    box-shadow: none;
    padding: 0;
    font-size: 20px;
    color: #7a7f9a;
  }
  @media (max-width: 575.98px) {
    display: flex;
    justify-content: flex-end;
  }
}

.chat-conversation {
  li {
    clear: both;
  }

  .chat-avatar {
    margin: 0 16px 0 0 /*rtl:0 0 0 16px*/;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }

  .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 12px;

    .title {
      background-color: #f0eff5;
      position: relative;
      font-size: 13px;
      z-index: 1;
      padding: 6px 12px;
      border-radius: 5px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: #f0eff5;
      top: 10px;
    }

    .badge {
      font-size: 12px;
    }
  }

  .conversation-list {
    margin-bottom: 24px;
    display: inline-flex;
    position: relative;
    align-items: flex-end;

    .ctext-wrap {
      display: flex;
      margin-bottom: 10px;
    }

    .ctext-wrap-content {
      padding: 12px 20px;
      background-color: #7269ef;
      border-radius: 8px 8px 8px 0;
      color: #fff;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        border: 5px solid transparent;
        border-left-color: #7269ef;
        border-top-color: #7269ef;
        left: 0 /*rtl:auto*/;
        right: auto /*rtl:0*/;
        bottom: -10px;
      }
    }

    .conversation-name {
      font-weight: 500;
      font-size: 14px;
    }

    .dropdown {
      .dropdown-toggle {
        font-size: 18px;
        padding: 4px;
        color: #7a7f9a;

        @media (max-width: 575.98px) {
          display: none;
        }
      }
    }

    .chat-time {
      margin: 4px 15px;
      font-size: 12px;
      text-align: right;
      color: #7a7f9a;
    }

    .message-img {
      border-radius: 0.2rem;
      position: relative;

      .message-img-list {
        position: relative;
      }

      img {
        max-width: 150px;
      }

      .message-img-link {
        position: absolute;
        right: 10px /*rtl:auto*/;
        left: auto /*rtl:0*/;
        bottom: 10px;

        li {
          > a {
            font-size: 18px;
            color: #fff;
            display: inline-block;
            line-height: 30px;
            width: 30px;
            height: 30px;
            text-align: center;
          }
        }
      }
    }
  }

  .right {
    .chat-avatar {
      order: 3;
      margin-right: 0px;
      margin-left: 16px;
    }

    .chat-time {
      text-align: left;
    }

    .conversation-list {
      float: right;
      text-align: right;

      .ctext-wrap {
        justify-content: flex-end;

        .ctext-wrap-content {
          order: 2;
          background-color: #7269ef;
          color: #ffffff;
          text-align: right;
          border-radius: 8px 8px 0px 8px;

          &:before {
            border: 5px solid transparent;
            border-top-color: #7269ef;
            border-right-color: #7269ef;
            left: auto;
            right: 0px;
          }
        }
      }

      .dropdown {
        order: 1;
      }
    }

    .dot {
      background-color: #343a40;
    }
  }
}

.chat-conversation {
  height: calc(100vh - 360px);
  @media (max-width: 991.98px) {
    height: calc(100vh - 152px);
  }
}

.chat-input-links {
  .list-inline-item:not(:last-child) {
    margin: 0;
  }
}

.animate-typing {
  .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: -1px;
    background: #fff;
    animation: wave 1.3s linear infinite;
    opacity: 0.6;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}


.react-autosuggest__container--open {
  .react-autosuggest__suggestions-container {
    margin-top: 2px;
    margin-left: 18px;
    display: block;
    position: absolute;
    top: 51px;
    width: 77%;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
  background-color: #0C7EAF;
  color: #fff;
}

.suggestion-content {
  display: flex;
  background-repeat: no-repeat;
}

.react-autosuggest_highlight {
  color: #ee0000;
  font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-5px);
  }
}
