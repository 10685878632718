.page-link {
  color: #ff9800;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.23);
  &:hover {
    color: #ff9800;
    background-color: rgba(255, 152, 0, 0.12);
  }
}

.page-item.active .page-link {
  color: #ff9800;
  background-color: rgba(255, 152, 0, 0.12);
  border: 1px solid rgba(255, 152, 0, 0.5);
}
